import React, {useEffect} from 'react';
import {
	Box,
	Button,
	FormControl,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputRightAddon,
	StackDivider,
	useToast,
	VStack,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import Paginator from '../../../components/Paginator';
import SidePanel from '../../../components/SidePanel';
import usePaginatedData from '../../../hooks/usePaginatedData';
import {Glum} from '../../../puddleglum';
import AddOrEditChargeForm from './forms/AddOrEditChargeForm';
import ProviderTreatmentSearchController from 'puddleglum/api/providers/ProviderTreatmentSearchController';

interface TreatmentSearchProps {
	user: Glum.Models.User;
	bookingRequest?: Glum.Models.BookingRequest;
}

const TreatmentSearch = ({user, bookingRequest}: TreatmentSearchProps) => {
	const [search, setSearch] = React.useState<string>('');
	const [selectedTreatment, setSelectedTreatment] = React.useState<Glum.Models.Treatment>();
	const toast = useToast();

	const {data: treatments, refetch} = useQuery(
		['provider-treatment-search'],
		async () => {
			if (search.length < 3) return Promise.resolve([]);

			const reply = await ProviderTreatmentSearchController.search({
				search,
			});

			if (reply.data.length === 0) {
				setSearch('');
				toast({
					title: 'No treatments found',
					description: 'No treatments were found with that search',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}

			return reply.data;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const clearResults = () => {
		setSelectedTreatment(undefined);
		setSearch('');
		// This makes sure refetch happens after the state change
		setTimeout(refetch, 0);
	};

	const paginator = usePaginatedData(treatments ?? [], {perPage: 10});

	useEffect(() => {
		if (treatments?.length === 1) {
			setSelectedTreatment(treatments[0]);
		}
	}, [treatments]);

	return (
		<>
			<Box mt={8}>
				<Heading size='sm' color='gray.500'>
					Add Charges
				</Heading>
				<FormControl>
					<InputGroup>
						<Input
							placeholder='Search for a charge to add'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
						<InputRightAddon
							as={Button}
							colorScheme='blue'
							bgColor='blue.500'
							borderColor='blue.500'
							onClick={() => refetch()}
							isDisabled={search.length < 3}
						>
							Search
						</InputRightAddon>
					</InputGroup>
				</FormControl>
			</Box>

			<SidePanel
				isOpen={!!selectedTreatment}
				onClose={clearResults}
				title='Enter Charge Details'
			>
				<Box pt={4}>
					<AddOrEditChargeForm
						user={user}
						treatment={selectedTreatment!}
						bookingRequest={bookingRequest}
						onClose={clearResults}
						onSave={clearResults}
					/>
				</Box>
			</SidePanel>

			<SidePanel
				isOpen={(treatments ?? []).length > 1}
				title='Select a Treatment'
				onClose={clearResults}
			>
				<VStack divider={<StackDivider borderColor='gray.200' />}>
					{paginator.paginatedData.map((treatment) => (
						<HStack key={treatment.id} w='full'>
							<Box flex={3}>{treatment.treatment}</Box>
							<Box flex={1}>{treatment.treatment_code}</Box>
							<Box>
								<Button
									variant='ghost'
									colorScheme='blue'
									onClick={() => {
										clearResults();
										setSelectedTreatment(treatment);
									}}
								>
									Select
								</Button>
							</Box>
						</HStack>
					))}
				</VStack>
				<Paginator
					currentPage={paginator.currentPage}
					totalPages={paginator.totalPages}
					onChange={paginator.setCurrentPage}
					size='xs'
				/>
			</SidePanel>
		</>
	);
};

export default TreatmentSearch;

import React, {useState} from 'react';
import {
	Box,
	Button,
	FormControl,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputRightAddon,
	Progress,
	Spacer,
	StackDivider,
	Text,
	VStack,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import ExpandableText from '../../../components/ExpandableText';
import SidePanel from '../../../components/SidePanel';
import {Glum} from '../../../puddleglum';
import AddOrEditChargeForm from './forms/AddOrEditChargeForm';
import OrdersController from 'puddleglum/api/providers/OrdersController';
import ProviderUserController from 'puddleglum/api/providers/ProviderUserController';

interface EhrChargesProps {
	user: Glum.Models.User;
	bookingRequest: Glum.Models.BookingRequest;
}

const EhrCharges = ({user, bookingRequest}: EhrChargesProps) => {
	const [inputMrn, setInputMrn] = useState('');
	const [currentUser, setCurrentUser] = useState<Glum.Models.User>(user);
	const [treatmentToAdd, setTreatmentToAdd] = useState<Glum.Models.Treatment>();

	const {data: ehrData, isLoading: isLoadingEhrData} = useQuery(
		['ehr-service-items', user.id],
		async () => {
			try {
				const reply = await OrdersController.getServiceItemsFromEhr(user.id);
				return reply.data;
			} catch (e) {
				return null;
			}
		},
		{
			enabled: !!currentUser.external_mrn,
		},
	);

	const setMrn = async (mrn: string) => {
		const reply = await ProviderUserController.setMrn(user.id, {mrn});
		setCurrentUser(reply.data);
		setInputMrn('');
	};

	const clearResults = () => {
		setTreatmentToAdd(undefined);
	};

	const hasMrn = !!currentUser.external_mrn;
	const hasError = ehrData === null && hasMrn;

	return (
		<>
			<Box mt={8}>
				<Heading size='sm' color='gray.500'>
					Charges from the EHR
				</Heading>

				{isLoadingEhrData && hasMrn && <Progress isIndeterminate size='xs' mt={4} />}

				{!isLoadingEhrData && hasError && (
					<>
						<Text mt={4} color='red.500'>
							There was an error retrieving charges from the EHR. Please try again
							later.
						</Text>
						<Button mt={4} size='sm' colorScheme='red' onClick={() => setMrn('')}>
							Disconnect from EHR
						</Button>
					</>
				)}

				{!hasMrn && (
					<Box mt={4}>
						<Text>
							You have not yet connected the patient to the EHR. Please enter the
							patient&apos;s MRN to connect.
						</Text>
						<HStack spacing={4} mt={4}>
							<FormControl>
								<InputGroup>
									<Input
										placeholder="Enter the patient's MRN"
										value={inputMrn}
										onChange={(e) => setInputMrn(e.target.value)}
									/>
									<InputRightAddon
										as={Button}
										colorScheme='teal'
										bgColor='primary.500'
										borderColor='primary.500'
										onClick={() => setMrn(inputMrn)}
										isDisabled={inputMrn.length < 3}
									>
										Connect to EHR
									</InputRightAddon>
								</InputGroup>
							</FormControl>
						</HStack>
					</Box>
				)}

				{hasMrn && ehrData && (
					<HStack
						w='full'
						bgColor='gray.50'
						border='solid 1px'
						borderColor='gray.200'
						borderRadius='md'
						p={2}
						mt={4}
						spacing={4}
					>
						<Box>
							<Text fontWeight='bold'>{ehrData.patient.name}</Text>
						</Box>
						<Box>
							<Text>
								<strong>MRN: </strong>
								{ehrData.patient.mrn}
							</Text>
						</Box>
						<Box>
							<Text>
								<strong>DOB: </strong>
								{ehrData.patient.dob}
							</Text>
						</Box>
						<Spacer />
						<Button
							size='sm'
							variant='ghost'
							colorScheme='red'
							onClick={() => setMrn('')}
						>
							Disconnect from EHR
						</Button>
					</HStack>
				)}

				{hasMrn && ehrData && ehrData.charges.length === 0 && (
					<Text mt={4}>No charges found</Text>
				)}

				{hasMrn && ehrData && ehrData.charges.length > 0 && (
					<VStack mt={4} w='full' divider={<StackDivider />}>
						{ehrData.charges.map((charge) => (
							<HStack key={charge.id} w='full' spacing={4}>
								<Box flex={6}>
									<ExpandableText>{charge.description}</ExpandableText>
								</Box>
								<Box flex={1}>
									<Text>{charge.code}</Text>
								</Box>
								<Box flex={1}>
									<Text>{charge.service_at}</Text>
								</Box>
								<Button
									minW='40px'
									size='sm'
									colorScheme='teal'
									onClick={() => setTreatmentToAdd(charge.treatment)}
								>
									Add Charge
								</Button>
							</HStack>
						))}
					</VStack>
				)}
			</Box>
			<SidePanel
				isOpen={!!treatmentToAdd}
				onClose={clearResults}
				title='Enter Charge Details'
			>
				<Box pt={4}>
					<AddOrEditChargeForm
						user={user}
						treatment={treatmentToAdd!}
						bookingRequest={bookingRequest}
						onClose={clearResults}
						onSave={clearResults}
					/>
				</Box>
			</SidePanel>
		</>
	);
};

export default EhrCharges;

import React from 'react';
import {Box, HStack, Icon, Link, Progress, Text, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdCheckCircle, MdOutlineCircle} from 'react-icons/md';
import {Link as ReactLink} from 'react-router-dom';
import ProviderOnboardingController from 'puddleglum/api/providers/ProviderOnboardingController';

const ProviderOnboarding = () => {
	const {data: onboardingStatus} = useQuery(['provider-onboarding'], async () => {
		const reply = await ProviderOnboardingController.getStatus();
		return reply.data;
	});

	const isEverythingCompleted =
		onboardingStatus?.is_profile_completed &&
		onboardingStatus?.are_locations_added &&
		onboardingStatus?.are_providers_added &&
		onboardingStatus?.is_stripe_connected;

	if (!onboardingStatus) return <Progress size='sm' isIndeterminate />;

	if (isEverythingCompleted)
		return (
			<Box mt={4}>
				<HStack>
					<Icon as={MdCheckCircle} color='green.500' boxSize={4} />
					<Text>You have completed all the onboarding steps!</Text>
				</HStack>
			</Box>
		);

	return (
		<VStack mt={4}>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.is_profile_completed ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.is_profile_completed ? 'green.500' : 'red.500'}
				/>
				<Link as={ReactLink} to='/settings' flex={1} p={0}>
					Complete Your Organization Profile
				</Link>
			</HStack>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.are_locations_added ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.are_locations_added ? 'green.500' : 'red.500'}
				/>
				<Link as={ReactLink} to='/locations' flex={1} p={0}>
					Add Your Locations
				</Link>
			</HStack>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.are_providers_added ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.are_providers_added ? 'green.500' : 'red.500'}
				/>
				<Link as={ReactLink} to='/providers' flex={1} p={0}>
					Add Your Providers
				</Link>
			</HStack>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.is_stripe_connected ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.is_stripe_connected ? 'green.500' : 'red.500'}
				/>
				<Link href='/provider/connect-with-stripe'>Connect to Stripe to Get Paid</Link>
			</HStack>
		</VStack>
	);
};

export default ProviderOnboarding;

import React, {FC} from 'react';
import {Box, Button, Flex, Heading, Table, Td, Tr, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import PuddleSelect from '../../../../components/fields/PuddleSelect';
import PuddleTextarea from '../../../../components/fields/PuddleTextarea';
import RequestTimeframes from '../../../../enums/RequestTimeframes';
import usePuddleForm from '../../../../hooks/usePuddleForm';
import {Glum} from '../../../../puddleglum';
import PuddleDateTime from 'components/fields/PuddleDateTime';
import LocationController from 'puddleglum/api/providers/LocationController';
import ProviderBookingController from 'puddleglum/api/providers/ProviderBookingController';

interface ConfirmBookingRequestFormProps {
	bookingRequest: Partial<Glum.Models.BookingRequest>;
	onSave: () => void;
	onClose: () => void;
	fieldsToUpdate?: Partial<Glum.Models.BookingRequest>;
}

const AddOrEditBookingRequestForm: FC<ConfirmBookingRequestFormProps> = ({
	bookingRequest = {},
	fieldsToUpdate = {},
	onSave,
	onClose,
}) => {
	const form = usePuddleForm<
		Glum.Models.BookingRequest,
		Glum.Requests.Provider.AddOrUpdateBookingRequest
	>({
		initialValues: {...bookingRequest, ...fieldsToUpdate},
		storeFunction: ProviderBookingController.store,
		updateFunction: ProviderBookingController.update,
	});

	const {data: locations} = useQuery(['locations'], async () => {
		const reply = await LocationController.index({
			page: 1,
			per_page: 10000,
			search: '',
		});
		return reply.data.data;
	});

	// Need to get Providers for the specific request location
	const {data: locationProviders} = useQuery(['location-providers'], async () => {
		const {data} = await ProviderBookingController.getLocationProviders(
			form.model?.location_id ?? 0,
		);
		return data;
	});

	const handleSubmit = async () => {
		const isSaved = await form.save();

		if (isSaved) {
			onSave();
		}
	};

	if (!bookingRequest.user) return null;

	return (
		<form>
			<Box w='full' border='solid 1px' borderColor='gray.200' p={4} borderRadius='md'>
				<Heading size='xs' color='gray.500'>
					Booking Details
				</Heading>
				<VStack>
					<PuddleSelect
						isRequired
						label='Status'
						options={[
							{label: 'Pending', value: 'pending'},
							{label: 'Confirmed', value: 'confirmed'},
							{label: 'Canceled', value: 'canceled'},
							{label: 'Completed', value: 'completed'},
						]}
						fieldName='status'
						form={form}
					/>
					<PuddleDateTime
						isRequired
						label='Appointment Date'
						fieldName='starts_at'
						form={form}
					/>
					<PuddleSelect
						isRequired
						label='Location'
						options={(locations ?? []).map((location) => ({
							label: location.name,
							value: location.id,
						}))}
						fieldName='location_id'
						form={form}
					/>
					<PuddleSelect
						isRequired
						label='Provider'
						options={(locationProviders ?? []).map((provider) => ({
							label: provider.name,
							value: provider.id,
						}))}
						fieldName='provider_id'
						form={form}
					/>
					<PuddleTextarea label='Notes' fieldName='notes' form={form} />
				</VStack>
			</Box>
			<Box
				w='full'
				bgColor='gray.50'
				border='solid 1px'
				borderColor='gray.200'
				p={4}
				mt={4}
				borderRadius='md'
			>
				<Heading size='xs' color='gray.500'>
					Patient Information
				</Heading>
				<Table size='sm'>
					<Tr>
						<Td>Name</Td>
						<Td>
							{bookingRequest.user.first_name} {bookingRequest.user.last_name}
						</Td>
					</Tr>
					<Tr>
						<Td>Email</Td>
						<Td>{bookingRequest.user.email}</Td>
					</Tr>
					<Tr>
						<Td>Home Phone</Td>
						<Td>{bookingRequest.user.home_phone}</Td>
					</Tr>
					<Tr>
						<Td>Cell Phone</Td>
						<Td>{bookingRequest.user.cell_phone}</Td>
					</Tr>
					<Tr>
						<Td>Address</Td>
						<Td>
							{bookingRequest.user.street_address1}
							{bookingRequest.user.street_address2 && (
								<>
									<br />
									{bookingRequest.user.street_address2}
								</>
							)}
							<br />
							{bookingRequest.user.city}, {bookingRequest.user.state}{' '}
							{bookingRequest.user.zip}
						</Td>
					</Tr>
				</Table>
			</Box>
			<Box
				w='full'
				bgColor='gray.50'
				border='solid 1px'
				borderColor='gray.200'
				p={4}
				mt={4}
				borderRadius='md'
			>
				<Heading size='xs' color='gray.500'>
					Appointment Information
				</Heading>
				<Table size='sm'>
					<Tr>
						<Td>Request Status</Td>
						<Td>{bookingRequest.status}</Td>
					</Tr>
					<Tr>
						<Td>Appointment Timeframe</Td>
						<Td>
							{
								RequestTimeframes[
									bookingRequest.requested_timeframe as keyof typeof RequestTimeframes
								]
							}
						</Td>
					</Tr>
					<Tr>
						<Td>Reason for Visit</Td>
						<Td>{bookingRequest.reason}</Td>
					</Tr>
				</Table>
			</Box>

			<Flex mt={8} mb={4} justifyContent='flex-end'>
				<Button variant='outline' onClick={onClose}>
					Close
				</Button>
				<Button ml={2} colorScheme='blue' onClick={handleSubmit}>
					Save
				</Button>
			</Flex>
		</form>
	);
};

export default AddOrEditBookingRequestForm;

/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class OrdersController {
	static async index(
		request: Glum.Requests.Provider.SearchOrdersRequest = {} as Glum.Requests.Provider.SearchOrdersRequest,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.Order[]>(
			`/api/provider/orders?${transformToQueryString(request)}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async store(
		request: Glum.Requests.Provider.AddOrder = {} as Glum.Requests.Provider.AddOrder,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<Glum.Models.Order>(`/api/provider/orders`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async show(
		order: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.Order>(`/api/provider/orders/${order}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async update(
		order: string | number,
		request: Glum.Requests.Provider.AddOrder = {} as Glum.Requests.Provider.AddOrder,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.put<Glum.Models.Order>(`/api/provider/orders/${order}`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async destroy(
		order: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.delete<{success: boolean}>(`/api/provider/orders/${order}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async getServiceItemsFromEhr(
		user: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<{
			patient: Record<'id' | 'name' | 'mrn' | 'dob', string>;
			charges: Array<{
				id: string;
				code: string;
				code_type: string;
				description: string;
				service_at: string;
				treatment: Glum.Models.Treatment;
			}>;
		}>(`/api/provider/ehr-service-items/${user}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class ProviderBookingController {
	static async index(
		request: Glum.Requests.Provider.SearchBookingRequests = {} as Glum.Requests.Provider.SearchBookingRequests,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.BookingRequest[]>(
			`/api/provider-booking-request?${transformToQueryString(request)}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async store(
		request: Glum.Requests.Provider.AddOrUpdateBookingRequest = {} as Glum.Requests.Provider.AddOrUpdateBookingRequest,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<Glum.Models.BookingRequest>(`/api/provider-booking-request`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async update(
		provider_booking_request: string | number,
		request: Glum.Requests.Provider.AddOrUpdateBookingRequest = {} as Glum.Requests.Provider.AddOrUpdateBookingRequest,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.put<Glum.Models.BookingRequest>(
			`/api/provider-booking-request/${provider_booking_request}`,
			request,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async getLocationProviders(
		location_id: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.Provider[]>(
			`/api/provider/location-providers/${location_id}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}
}

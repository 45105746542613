import React, {createContext, FC, useContext, useEffect, useRef} from 'react';
import {useQuery} from '@tanstack/react-query';
import LaravelEcho from '../../js/echo';
import {Notification as NotificationType} from '../types/Notification';
import assetUrl from '../utils/assetUrl';
import NotificationController from 'puddleglum/api/NotificationController';

type NotificationContextValue = {
	registerCallback: (channel: string, callback: (notification: NotificationType) => void) => void;
	notifications?: NotificationType[];
	refetch: () => void;
};

const NotificationContext = createContext({} as NotificationContextValue);

export const useNotifications = () => {
	const context = useContext(NotificationContext);
	if (context === undefined) {
		throw new Error('useNotifications must be used within a NotificationProvider');
	}
	return context;
};

export const NotificationProvider: FC<{children: React.ReactNode}> = ({children}) => {
	const initialTitle = document.title;
	const registeredChannels = useRef<string[]>([]);
	const [isPushEnabled, setIsPushEnabled] = React.useState<boolean>(false);
	const {data: notifications, refetch} = useQuery(['notifications'], async () => {
		const response = await NotificationController.getNotifications();
		return response.data;
	});
	const unreadNotifications = notifications?.filter((notification) => !notification.read_at);

	const registerCallback = (
		channel: string,
		callback: (notification: NotificationType) => void,
	) => {
		// Prevent registering the same channel multiple times
		if (!registeredChannels.current.includes(channel)) {
			registeredChannels.current.push(channel);
			LaravelEcho.private(channel).notification((notification: NotificationType) => {
				if (isPushEnabled) {
					const browserNotification = new Notification(notification.title, {
						body: notification.body,
						icon: assetUrl('/images/allora-logo.png'),
						tag: notification.id,
					});
					browserNotification.onclick = () => {
						browserNotification.close();
						window.parent.focus();
					};
				}

				callback?.(notification);
				refetch();
			});
		}
	};

	const enablePushNotifications = async () => {
		try {
			const permission = await Notification.requestPermission();
			setIsPushEnabled(permission === 'granted');
		} catch (error) {
			console.error('Error enabling push notifications:', error);
		}
	};

	useEffect(() => {
		if (!unreadNotifications?.length || unreadNotifications.length === 0) {
			document.title = initialTitle;
		} else if (unreadNotifications.length === 1) {
			document.title = `(${unreadNotifications.length}) New Notification | ${initialTitle}`;
		} else if (unreadNotifications.length > 1) {
			document.title = `(${unreadNotifications.length}) New Notifications | ${initialTitle}`;
		}

		return () => {
			document.title = initialTitle;
		};
	}, [notifications?.length]);

	useEffect(() => {
		enablePushNotifications();
	}, []);

	return (
		<NotificationContext.Provider
			value={{
				registerCallback,
				notifications,
				refetch,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

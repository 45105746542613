import React from 'react';
import {Box, HStack} from '@chakra-ui/react';
import {Glum} from '../../../puddleglum';

const PatientBanner = ({patient}: {patient: Glum.Models.User}) => (
	<HStack
		bgColor='yellow.50'
		border='solid 1px'
		borderColor='gray.200'
		borderRadius='md'
		p={4}
		spacing={4}
	>
		<Box>
			<strong>DOB</strong>: {patient.date_of_birth}
		</Box>
		<Box>
			<strong>Sex</strong>: {patient.sex}
		</Box>
		<Box>
			<strong>Phone</strong>: {patient.cell_phone}
		</Box>
		<Box>
			<strong>City</strong>: {patient.city}, {patient.state}
		</Box>
		<Box>
			<strong>MRN</strong>: {patient.external_mrn ?? 'N/A'}
		</Box>
	</HStack>
);

export default PatientBanner;

/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, {AxiosRequestConfig} from 'axios';
import {Glum} from 'puddleglum';
import {transformToQueryString, PaginatedResponse} from 'puddleglum/utils';

export default class OrganizationProviderController {
	static async processCsv(
		request: Glum.Requests.Provider.ProcessProviderCsv = {} as Glum.Requests.Provider.ProcessProviderCsv,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<{message: string}>(`/api/provider/roster/csv`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async index(
		request: {page: number; per_page: number; search: string},
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<PaginatedResponse<Glum.Models.Provider>>(
			`/api/provider/provider?${transformToQueryString(request)}`,
			{
				headers: {
					Precognition: validationOnly,
					...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
				},
				...config,
			},
		);
	}

	static async store(
		request: Glum.Requests.Provider.AddProvider = {} as Glum.Requests.Provider.AddProvider,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.post<Glum.Models.Provider>(`/api/provider/provider`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async show(
		provider: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.Provider>(`/api/provider/provider/${provider}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async update(
		provider: string | number,
		request: Glum.Requests.Provider.AddProvider = {} as Glum.Requests.Provider.AddProvider,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.put<Glum.Models.Provider>(`/api/provider/provider/${provider}`, request, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async destroy(
		provider: string | number,
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.delete<{success: boolean}>(`/api/provider/provider/${provider}`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}

	static async specialties(
		validationOnly: boolean = false,
		fieldToValidate: string = '',
		config: AxiosRequestConfig = {},
	) {
		return axios.get<Glum.Models.Specialty[]>(`/api/provider/specialties`, {
			headers: {
				Precognition: validationOnly,
				...(fieldToValidate ? {'Precognition-Validate-Only': fieldToValidate} : {}),
			},
			...config,
		});
	}
}
